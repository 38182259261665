<template>
  <div>
    <div>
      <div id="preloader" v-if="st_loading">
        <div class="preloader"><span></span><span></span></div>
      </div>
      <cmp-topnavbar v-if="co_IsMobile" />
      <transition name="fade-in-up">
        <router-view :style="co_IsMobile ? 'padding-top: 80px' : ''"/>
      </transition>
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { m_isMobile } from "../../lib-core/lib-helpers/lib-utils";
import c_topNavbar from "../cmp-components/cmp-topnavbar.vue";

export default {
  name: "cmp-accesslayout",
  components: {
    "cmp-topnavbar": c_topNavbar,
  },
  computed: {
    co_IsMobile() {
      return m_isMobile();
    },
    ...mapState({
      st_loading: (st_state) => st_state.md_fire.st_loading,
    }),
  },
};
</script>

<style>
</style>