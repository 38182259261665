<template>
  <section class="error-wrap" style="min-height: 95vh; padding-top: 30vh">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-10">
          <div class="text-center">
            <img src="/img/404.png" class="img-fluid" alt="" />
            <p style="padding-top: 20px; padding-bottom: 20px">
              Sorry, we can’t find the page you were looking for.
            </p>
            <a class="btn btn-theme" @click="m_backHome">Back To Home</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "cmp-error",
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1500);
  },
  methods: {
    m_backHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style>
</style>