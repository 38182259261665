<template>
  <div
    class="header header-light head-shadow"
    style="z-index: 101; height: 70px"
  >
    <div
      v-if="false"
      width="100%"
      height="10px"
      style="
        z-index: 999999999;
        background: #eee;
        color: #83bf6f;
        text-align: center;
        font-size: 12px;
      "
    >
      Please note that 971tutors.com is running in testing mode
    </div>
    <div
      class="container-fluid"
      style="background: white; padding-top: env(safe-area-inset-top)"
    >
      <b-navbar
        fixed="top"
        toggleable="lg"
        id="navigation"
        class="navigation navigation-landscape"
        v-on-clickaway="g_isMobile ? emptyFunc : m_closeNav"
      >
        <transition name="fade">
          <p
            class="h3 p-0 m-0"
            v-show="c_isMobile() && !isMainPage() && co_showBackButton"
            @click="goBack()"
          >
            <b-icon icon="arrow-left"></b-icon>
          </p>
        </transition>
        <transition name="fade">
          <img
            @click="
              m_closeNav();
              m_route('home');
            "
            src="/img/logo.png"
            class="logo"
            style="cursor: pointer; width: 100px; padding-bottom: 15px"
          />
        </transition>

        <b-navbar-toggle
          v-if="!g_isMobile"
          style="float: right"
          target="nav-collapse"
        ></b-navbar-toggle>

        <b-navbar-toggle
          v-else
          style="float: right"
          target="sidebar-right"
        ></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              class="main-nav-item"
              @click="m_route('education')"
              :active="m_isActiveRoute('education')"
              active-class="menu-highlighted"
            >
              Education
            </b-nav-item>

            <b-nav-item
              class="main-nav-item"
              @click="m_route('subjects')"
              :active="m_isActiveRoute('subjects')"
              active-class="menu-highlighted"
              >Subjects
            </b-nav-item>
            <b-nav-item
              class="main-nav-item"
              v-if="
                !gt_isAuthenticated ||
                !gt_user ||
                !gt_user.account ||
                gt_user.account.role != 'tutor'
              "
              @click="m_route('packages')"
              :active="m_isActiveRoute('packages')"
              active-class="menu-highlighted"
              >Packages
            </b-nav-item>

            <b-nav-item
              class="main-nav-item"
              @click="m_route('about')"
              :active="m_isActiveRoute('about')"
              active-class="menu-highlighted"
              >About
            </b-nav-item>

            <b-nav-item
              class="main-nav-item"
              @click="m_route('contact')"
              :active="m_isActiveRoute('contact')"
              active-class="menu-highlighted"
              >Contact
            </b-nav-item>
            <b-nav-item
              id="become-tutor-btn"
              class="main-nav-item"
              v-if="!gt_isAuthenticated"
              @click="m_goToBecomeATutor"
              active-class="menu-highlighted"
              >Become a Tutor
            </b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <button
              v-if="
                !gt_isAuthenticated ||
                !gt_user ||
                !gt_user.account ||
                gt_user.account.role != 'tutor'
              "
              @click="
                m_closeNav();
                m_route('checkout');
              "
              class="btn btn-main-cart"
            >
              <b-icon-cart4
                style="color: #647b9c; opacity: 0.6"
                font-scale="1"
              ></b-icon-cart4>
              <b-badge
                id="cart-badge-main"
                pill
                style="background: #f5f5f5; color: #647b9c"
                >{{ gt_getCartSize }}</b-badge
              >
              <!-- <span class="icon-button__badge">{{ gt_getCartSize }}</span> -->
            </button>
            <button
              v-if="!gt_isAuthenticated"
              @click="
                m_closeNav();
                m_route('as');
              "
              class="btn btn-light"
              style="
                width: 100%;
                min-width: 203px;
                margin: 0;
                color: #647b9c;
                height: 70px;
              "
            >
              Sign in
            </button>

            <button
              v-else-if="gt_isAuthenticated && gt_user && gt_user.account"
              v-b-toggle.sidebar-right
              class="btn btn-light"
              style="
                width: 100%;
                min-width: 203px;
                margin: 0;
                color: #647b9c;
                height: 70px;
              "
            >
              <cmp-tutoravatar
                style="display: inline"
                p_size="2rem"
                :p_name="gt_user.account.displayName"
                :p_email="gt_user.account.email"
                :p_avatar="gt_user.account.avatar"
              ></cmp-tutoravatar>
              <span>
                {{
                  gt_user.account.displayName &&
                  gt_user.account.displayName != ""
                    ? gt_user.account.displayName
                    : gt_user.account.email
                }}
              </span>
            </button>
            <button
              v-else
              @click="
                m_closeNav();
                m_route('as');
              "
              class="btn btn-light"
              style="
                width: 100%;
                min-width: 203px;
                margin: 0;
                color: #647b9c;
                height: 70px;
              "
            >
              Sign in
            </button>
            <span
              class="hide-version"
              v-show="c_isMobile"
              ref="version"
              v-if="false"
              >version 18.0</span
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-sidebar
      sidebar-class="marginIOS"
      id="sidebar-right"
      bg-variant="white"
      right
      shadow
      no-header
      backdrop-variant="transparent"
      backdrop
    >
      <cmp-dashboardsidebar></cmp-dashboardsidebar>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import c_tutorAvatar from "./cmp-tutoravatar";
import c_dashboardSidebar from "./cmp-dashboardsidebar";
import { mixin as clickaway } from "vue-clickaway";
import { ios, android } from "platform-detect";
import { m_isMobile } from "../../lib-core/lib-helpers/lib-utils";
import { g_isMobile } from "../../lib-core/lib-helpers/lib-utils";

export default {
  name: "cmp-topnavbar",
  mixins: [clickaway],
  mounted() {
    if (android && this.$refs.version != null)
      this.$refs.version.classList.value = "";
  },
  components: {
    "cmp-tutoravatar": c_tutorAvatar,
    "cmp-dashboardsidebar": c_dashboardSidebar,
  },
  props: {
    p_showBackButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      prevRoute: null,
    };
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user"]),
    ...mapGetters("md_auth", [
      "gt_isAuthenticated",
      "gt_isVerified",
      "gt_isFacebookLogin",
    ]),
    ...mapGetters("md_cart", ["gt_getCartSize"]),
    co_showBackButton() {
      return this.p_showBackButton;
    },
    g_isMobile() {
      return g_isMobile;
    },
  },
  methods: {
    goBack() {
      this.$store.commit("md_fire/mt_setDashboardSection", "");
      this.$router.go(-1);
    },
    emptyFunc() {},
    c_isMobile() {
      return m_isMobile();
    },
    isMainPage() {
      return this.$router.history.current.path == "/";
    },
    m_route(p_name) {
      if (this.$route.name == p_name && p_name == "checkout") {
        this.$root.$emit("m_backtocart");
      }
      if (this.$route.name == p_name) return;
      if (
        this.gt_user &&
        this.gt_user.account &&
        this.gt_user.account.role == "tutor" &&
        (!this.gt_user.account.address ||
          this.gt_user.account.address.trim().length == 0)
      ) {
        let l_payload = {
          message: "Finish Account Setup to proceed",
          button: {
            yes: "Ok",
          },
        };
        this.$confirm(l_payload);
        return;
      }

      this.$store.commit("md_fire/mt_setDashboardSection", null);
      // this.$store.commit("md_fire/mt_setLoading", true);
      this.$router.push({ name: p_name });
      this.m_topFunction();
    },
    m_topFunction() {
      window.document.body.scrollTop = 0; // For Safari
      window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async m_goToBecomeATutor() {
      await this.$localforage.setItem("fs_tempRole", "tutor");
      this.m_route("access");
    },
    m_getHightlightClass(p_name) {
      if (this.$route.name == p_name) return "menu-highlighted";
      return;
    },
    m_isActiveRoute(p_name) {
      return this.$route.name == p_name;
    },
    m_closeNav() {
      let l_togglers = window.document.getElementsByClassName(
        "navbar-toggler not-collapsed"
      );
      l_togglers.forEach((p_element) => {
        if (p_element) p_element.click();
      });
    },
  },
};
</script>

<style scoped>
.hide-version {
  display: none;
}
.nav-link {
  color: #647b9c !important;
  font-weight: 100;
  margin-left: 25px;
}
.nav-link.active {
  color: #82bf6f !important;
  font-weight: 100;
}
.menu-highlighted {
  color: #82bf6f !important;
}
.btn-main-cart {
  background-color: unset !important;
  color: unset !important;
}
.btn-main-cart:hover {
  background-color: unset !important;
  color: unset !important;
}
.main-nav-item {
  color: #fff !important;
  /* font-weight: 600; */
  font-size: 15px;
}

.main-nav-item.active,
.main-nav-item.focus,
.main-nav-item:hover {
  color: #82bf6f !important;
}
#nav-collapse {
  margin-left: 5vw;
}

.navigation {
  width: 100vw;
  padding: 0;
}
#cart-badge-main {
  position: absolute;
  top: 20px;
}
@media only screen and (max-width: 1140px) {
  #nav-collapse {
    margin-left: 0px;
  }
  #become-tutor-btn {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  #cart-badge-main {
    position: absolute;
    top: 300px;
  }
  .navigation {
    width: 100%;
    padding: 5px;
  }
  #nav-collapse {
    margin: auto;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
  }
  .main-nav-item {
    padding: 5px 15px;
    color: #fff !important;
    /* font-weight: 600; */
    font-size: 15px;
  }
  .navbar-nav {
    text-align: center;
    width: 100%;
  }
  .navbar-brand {
    padding: 0px 0px;
    font-size: 12px;
  }
}
</style>
<style>
.navbar-nav .b-avatar-text {
  color: white !important;
}
.marginIOS {
  margin-top: env(safe-area-inset-top)!important;
}
</style>