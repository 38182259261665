import { firestoreAction } from 'vuexfire';
import {
    fb_studentsCollection, fb_tutorsCollection, fb_usersCollection,
    fb_studentSessionsCollection, fb_tutorSessionsCollection, fb_tutorSubjectsCollection,
    fb_packagesCollection,
    fb_curriculumsRef, fb_categoriesRef, fb_subjectsRef, fb_FieldValue, fb_sessionSettings
} from '../lib-firebase';

import { g_preferredCurriculums } from '../lib-helpers/lib-constants';
import { m_sortObjectByPreference, m_subjectsSerializer } from '../lib-helpers/lib-utils';
import localforage from "localforage";

const l_state = {
    st_loading: true,
    st_PageNoInternet: false,
    st_user: null,
    st_sessions: [],
    st_education: 'a',
    st_educationSubjects: 'a',
    st_educationLevels: [
        'a',
        's',
        'u',
        'l',
        'o',
    ],
    st_grades: [
        '1-5',
        '6-10',
        '11-12',
    ],
    st_curriculum: null,
    st_curriculums: null,
    st_categories: null,
    st_category: 'all',
    st_subject: null,
    st_dashboardSection: null,
    st_tutorSubjects: [],
    st_packages: [],
    st_subjects: null,
    st_homepageSubject: {},
    st_homepageGrade: "",
    st_userBinded: null,
    st_sessionPrice: 0,
};

const l_mutations = {
    mt_sessionPrice(l_state, p_payload) {
        l_state.st_sessionPrice = p_payload;
    },
    mt_setUserBinded(l_state, p_payload) {
        l_state.st_userBinded = p_payload;
    },
    mt_setSubjectsEducation(l_state, p_payload) {
        l_state.st_educationSubjects = p_payload;
    },
    mt_setHomepageSubject(l_state, p_payload) {
        l_state.st_homepageSubject = p_payload;
    },
    mt_setHomepageGrade(l_state, p_payload) {
        l_state.st_homepageGrade = p_payload;
    },
    mt_setLoading(l_state, p_payload) {
        l_state.st_loading = p_payload;
    },
    mt_setUser(l_state, p_payload) {
        l_state.st_user = p_payload;
    },
    mt_setCurriculum(l_state, p_payload) {
        l_state.st_curriculum = p_payload;
    },
    mt_setCurriculums(l_state, p_payload) {
        l_state.st_curriculums = p_payload;
    },
    mt_setEducation(l_state, p_payload) {
        l_state.st_education = p_payload;
    },
    mt_setCategories(l_state, p_payload) {
        l_state.st_categories = p_payload;
    },
    mt_setCategory(l_state, p_payload) {
        l_state.st_category = p_payload;
    },
    mt_setDashboardSection(l_state, p_payload) {
        l_state.st_dashboardSection = p_payload;
    },
    mt_setPackages(l_state, p_payload) {
        l_state.st_packages = p_payload;
    },
    mt_setSubjects(l_state, p_payload) {
        l_state.st_subjects = p_payload;
    },
    mt_setSubject(l_state, p_payload) {
        l_state.st_subject = p_payload;
    },
    mt_setPageNoInternet(l_state, p_payload) {
        l_state.st_PageNoInternet = p_payload;
    }
}


const l_actions = {
    initPackages: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef("st_packages", fb_packagesCollection);
    }),
    initSessionPrice: firestoreAction(({ bindFirestoreRef }) => {
        return bindFirestoreRef("st_sessionPrice", fb_sessionSettings);
    }),
    ac_login(l_context, p_payload) {
        l_context.commit("mt_setUser", p_payload);
    },
    async ac_createOrBind(l_context, p_payload) {
        var l_docRef = fb_usersCollection.doc(p_payload.email);
        try {
            const l_document = await l_docRef.get();
            if (l_document.exists) {
                const l_role = l_document.data().role;
                if (!l_document.data().emailVerified) {
                    await l_docRef.set({ 'emailVerified': p_payload.emailVerified }, { merge: true });
                }
                if (['student', 'tutor'].indexOf(l_role) == -1)
                    l_context.dispatch("md_auth/ac_logout", null, { root: true });
                else
                    await l_context.dispatch("ac_bindUser", { role: l_document.data().role, key: p_payload.email });
                //st_user exists
            }
            else {
                // doc.data() will be undefined in this case
                //new st_user
                await l_docRef.set({ 'role': p_payload.tempRole, emailVerified: p_payload.emailVerified })
                const collection = p_payload.tempRole == 'tutor' ? fb_tutorsCollection : fb_studentsCollection;

                await collection.doc(p_payload.email).set({ account: { email: p_payload.email, avatar: "", displayName: p_payload.displayName || "", role: p_payload.tempRole, ratingCount: 0, ratingValue: 0, registered: fb_FieldValue.serverTimestamp() }, additional: {}, status: { account: 'A' } });
                await l_context.dispatch("ac_bindUser", { role: p_payload.tempRole, key: p_payload.email });
                return true;
            }
        } catch (p_element) {
            //console.log("Error getting l_document:", p_element);
        }
    },

    ac_bindUser: firestoreAction(
        async ({ bindFirestoreRef, commit }, p_payload) => {

            switch (p_payload.role) {
                case 'tutor':
                    await bindFirestoreRef('st_user', fb_tutorsCollection.doc(p_payload.key)),
                        commit("mt_setUserBinded", true);

                    await Promise.all([
                        bindFirestoreRef('st_sessions', fb_tutorSessionsCollection.doc(p_payload.key).collection('sessions')),
                        bindFirestoreRef('st_tutorSubjects', fb_tutorSubjectsCollection.doc(p_payload.key).collection('subjects'))
                    ]);
                    break;
                case 'student':
                    await bindFirestoreRef('st_user', fb_studentsCollection.doc(p_payload.key));
                    commit("mt_setUserBinded", true);

                    await bindFirestoreRef('st_sessions', fb_studentSessionsCollection.doc(p_payload.key).collection('sessions'))
                    break;
            }
            return true;
        }
    ),
    async ac_init(l_context) {
        return await l_context.dispatch('ac_fetchInitData');
    },
    ac_unbind: firestoreAction(
        ({ unbindFirestoreRef }) => {
            unbindFirestoreRef('st_user')
            unbindFirestoreRef('st_sessions')
            unbindFirestoreRef('st_tutorSubjects')
        }
    ),
    async ac_fetchInitData(l_context) {
        return Promise.all[
            l_context.dispatch('ac_fetchCurriculums'),
            l_context.dispatch('ac_fetchPackages'),
            l_context.dispatch('ac_fetchSessionPrice')
        ];
    },
    ac_bindSubjects: firestoreAction(
        async ({ bindFirestoreRef }) => {
            bindFirestoreRef('st_subjects', fb_subjectsRef, { serialize: m_subjectsSerializer })
            return true
        }
    ),
    ac_bindCategories: firestoreAction(
        async ({ bindFirestoreRef }) => {
            bindFirestoreRef('st_categories', fb_categoriesRef)
            return true
        }
    ),
    async ac_fetchCurriculums(l_context) {
        return await fb_curriculumsRef
            .get()
            .then(p_snapshot => {
                const l_document = p_snapshot.data()
                l_context.commit("mt_setCurriculums", m_sortObjectByPreference(l_document, g_preferredCurriculums));
            });
    },
    async ac_fetchPackages(l_context) {
        await l_context.dispatch("initPackages");
    },
    async ac_fetchSessionPrice(l_context) {
        await l_context.dispatch("initSessionPrice");
    },

    async ac_getSubjectById(l_context, p_payload) {
        if (l_context.state.st_subjects == null) return null;
        return l_context.state.st_subjects[p_payload];
    },
    async ac_getSubjectByKey(l_context, p_payload) {
        return l_context.state.st_subjects[p_payload] || { en: 'Not Specified.' };
    },
    async ac_changeCartSessionPrices(l_context) {
        let cartProducts = await localforage.getItem("fs_cart");
        cartProducts.forEach((product) => {
            if (product.type == "s")
                product.price = l_state.st_sessionPrice.price;
        });
        await localforage.setItem("fs_cart", cartProducts);
    },
    async ac_changeCartPackagePrices(l_context) {
            const initialValue = {};
            let packages = l_state.st_packages.reduce((obj, item) => {
              return {
                ...obj,
                [item['id']]: item,
              };
            }, initialValue);
        let cartProducts = await localforage.getItem("fs_cart");
        cartProducts.forEach((product) => {
            if (product.type == "p" && packages[product.id] != null)
            {
                product.price = packages[product.id].price;
            }
        });
        await localforage.setItem("fs_cart", cartProducts);
    },
};

const l_getters = {
    gt_sessionPrice(l_state) {
        l_actions.ac_changeCartSessionPrices();
        return l_state.st_sessionPrice.price;
    },
    gt_packages(l_state) {
        l_actions.ac_changeCartPackagePrices();
        return l_state.st_packages
    },
    gt_dashboardSection(l_state) {
        switch (l_state.st_dashboardSection) {
            case 'sessions': return 'Sessions';
            case 'account': return 'Account';
            case 'tutoringsubjects': return 'Tutoring Subjects';
            case 'home': return '';
        }
        return '';
    },
    gt_getEducationSubjects(l_state) {
        return l_state.st_educationSubjects;
    },
    gt_getHomepageSubject(l_state) {
        return l_state.st_homepageSubject;
    },
    gt_getHomepageGrade(l_state) {
        return l_state.st_homepageGrade;
    },
    gt_getGrades(l_state) {
        return l_state.st_grades;
    },
    gt_user(l_state) {
        return l_state.st_user;
    },
    gt_sessions(l_state) {
        let a = [...l_state.st_sessions];
        a.sort((a, b) => (a.date > b.date) ? -1 : 1);
        return a;
        // const l_temp = [
        //     {
        //         subject: 'l_la_arabic',
        //         tutor: 'mounzer.dev@gmail.com',
        //         status: 'A'
        //     },
        //     {
        //         subject: 'l_la_arabic',
        //         tutor: 'mounzer.dev@gmail.com',
        //         status: 'C'
        //     }
        // ];
        // return l_temp;
        // return l_state.st_sessions;
        // if (l_state.st_sessions == null) return [];
        // let l_temp = [...l_state.st_sessions];
        // return l_temp.sort((a, b) => b.date.toDate() - a.date.toDate());
    },

    gt_notSpecifiedSessions(l_state, l_getters) {
        return l_getters.gt_sessions.filter((p_element) => {
            if (!p_element.tutor && !p_element.subject) return p_element;
        });
    },
    gt_notSpecifiedSessionsExists(l_state, l_getters) {
        return l_getters.gt_notSpecifiedSessions.length > 0;
    },
    gt_getLastNotSpecifiedSession(l_state, l_getters) {

        if (l_getters.gt_notSpecifiedSessions.length == 0) return null;
        return l_getters.gt_notSpecifiedSessions[0];
    },

    gt_curriculums(l_state) {
        return l_state.st_curriculums;
    },
    gt_curriculumSelected(l_state) {
        if (!l_state.st_curriculum || !l_state.st_curriculums) return null;

        return { ...l_state.st_curriculums[l_state.st_curriculum], key: l_state.st_curriculum };
    },
    gt_curriculumsSelectItem(l_state) {
        if (l_state.st_curriculums == null) {
            return [];
        }
        let l_options = [];
        for (var l_key in l_state.st_curriculums) {
            let l_element = { key: l_key, ...l_state.st_curriculums[l_key] };
            l_options.push(l_element);
        }

        return l_options;
    },
    gt_categories: (l_state) => {
        let l_categories = {};
        if (l_state.st_categories == null) return null;

        if (l_state.st_educationSubjects == 'a')
            l_state.st_educationLevels.forEach((education) => {
                l_categories = { ...l_categories, ...l_state.st_categories[education] };
            })
        else {
            l_categories = { ...l_state.st_categories[l_state.st_educationSubjects] };
        }

        return l_categories;
    },
    gt_educationsCategories: (l_state) => {

        if (!l_state.st_categories || !l_state.st_education) return null;
        return l_state.st_categories[l_state.st_education];
    },
    gt_userRole: (l_state) => {
        let l_role = "student";
        if (l_state.st_user != null && l_state.st_user.account != null && l_state.st_user.account.role == "tutor") {
            l_role = "tutor";
        }
        return l_role;
    },
    gt_userStatus: (l_state) => {
        return l_state.st_user && l_state.st_user.status ? l_state.st_user.status.account : null;
    },
    gt_totalSessionsCount(l_state) {
        return l_state.st_sessions.length;
    },
    gt_availableSessionsCount(l_state) {
        return l_state.st_sessions.filter((p_element) => p_element.status == 'A').length;
    },
    gt_pendingSessionsCount(l_state) {
        return l_state.st_sessions.filter((p_element) => ['A', 'CA'].indexOf(p_element.status) == -1).length;
    },
    gt_completedSessionsCount(l_state) {
        return l_state.st_sessions.filter((p_element) => p_element.status == 'CA').length;
    },
    gt_subjects: (l_state) => {
        if (!l_state.st_subjects) return;
        let l_s = Object.values(l_state.st_subjects);
        l_s.sort((a, b) => {
            return (a.en.toLowerCase() > b.en.toLowerCase()) ? 1 : -1
        });
        return l_s;
    },
    gt_filteredSubjects(l_state) {
        if (l_state.st_subjects == null) return {};
        let l_ret = {};
        for (let [key, value] of Object.entries(l_getters.gt_subjects(l_state))) {
            if ((l_state.st_educationSubjects == value.education
                &&
                (l_state.st_category == 'all' || l_state.st_category.includes(value.category)))
                || l_state.st_educationSubjects == 'a')
                //add search txt value
                l_ret[key] = { ...value }
        }
        return l_ret;
    },

}



export default {
    namespaced: true,
    state: l_state,
    actions: l_actions,
    mutations: l_mutations,
    getters: l_getters
};