<template>
  <div class="card vue-avatar-cropper-demo text-center">
    <div :class="[g_isMobile ? 'card-body-mobile' : '', 'card-body']">
      <cmp-tutoravatar
        :class="['card-img avatar', g_isMobile ? 'avatar-mobile' : '']"
        :p_name="p_displayName"
        :p_email="p_email"
        :p_avatar="d_img"
        p_size="6rem"
      ></cmp-tutoravatar>
      <div
        :class="[
          g_isMobile ? 'card-img-overlay-mobile' : '',
          'card-img-overlay',
        ]"
      >
        <button
          style="border-radius: 20%"
          class="btn btn-primary btn-sm"
          id="pick-avatar"
          @click="chooseImage"
        >
          <b-icon-image></b-icon-image>
        </button>
      </div>
      <h5 v-if="!g_isMobile" class="card-title mb-0">{{ p_displayName }}</h5>
      <div class="text-muted">{{ p_email }}</div>
    </div>
    <div
      :class="[
        'card-footer',
        'text-muted',
        g_isMobile ? 'card-footer-mobile' : '',
      ]"
      style="text-transform: capitalize"
      v-html="p_role"
    ></div>
    <cmp-tutorating
      v-if="p_role == 'tutor'"
      :p_ratingCount="p_ratingCount"
      :p_ratingValue="p_ratingValue"
      :p_readonly="true"
    />
    <b-modal
      ref="imageModal"
      id="imageModal"
      :cancel-disabled="false"
      :ok-disabled="!imageSet"
      scrollable
      lazy
      centered
      size="md"
      title="Choose image"
      @ok="generateImage"
    >
      <div class="sss" ref="imageCropper"></div>
      <croppa
        v-model="myCroppa"
        canvas-color="white"
        :placeholder="'Choose a photo'"
        :accept="'image/*'"
        :zoom-speed="zoomSpeed"
        static
        :show-remove-button="false"
        :show-loading="true"
        :fileSizeLimit="5242880"
        @file-size-exceed="handleCroppaFileSizeExceed"
        @file-type-mismatch="handleCroppaFileTypeMismatch"
        @file-choose="handleCroppaFileChoose"
      ></croppa>
      <b-row v-if="imageSet">
        <b-col class="d-flex justify-content-start">
          <b-button @click="rotateImage(-1)">Rotate left</b-button>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button @click="rotateImage(1)">Rotate right</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import c_tutorAvatar from "./cmp-tutoravatar";
import c_tutorRating from "./cmp-tutorrating";

import {
  fb_profilestorageref,
  fb_tutorsCollection,
  fb_studentsCollection,
} from "@/lib-core/lib-firebase";

import { g_isMobile, m_isMobile } from "../../lib-core/lib-helpers/lib-utils";

export default {
  name: "cmp-useravatar",
  components: {
    // ImageUploader,
    // "avatar-cropper": c_avatarCropper,
    "cmp-tutoravatar": c_tutorAvatar,
    "cmp-tutorating": c_tutorRating,
  },
  props: {
    p_displayName: {
      type: String,
    },
    p_email: {
      type: String,
    },
    p_role: {
      type: String,
    },
    p_avatar: {
      type: String,
    },
    p_ratingCount: {
      type: Number,
    },
    p_ratingValue: {
      type: Number,
    },
  },
  data() {
    return {
      d_inputFile: null,
      d_imgurl: null,
      d_hasimage: false,
      d_originalImg: null,
      d_img: this.p_avatar,
      myCroppa: {},
      imgUrl: "",
      rotationDegree: 0,
    };
  },
  computed: {
    g_isMobile() {
      return g_isMobile;
    },
    zoomSpeed() {
      return m_isMobile() ? 1 : 6;
    },
    imageSet() {
      return this.myCroppa.imageSet;
    },
  },
  methods: {
    rotateImage(e) {
      this.myCroppa.rotate(e);
    },
    handleCroppaFileChoose(e) {},
    handleCroppaFileTypeMismatch(e) {
      let l_payload = {
        message: "Only images are supported",
        button: {
          yes: "Ok",
        },
      };
      this.$confirm(l_payload);
    },
    handleCroppaFileSizeExceed(e) {
      let l_payload = {
        message: "You have exceeded the 5 MB size limit",
        button: {
          yes: "Ok",
        },
      };
      this.$confirm(l_payload);
    },
    chooseImage() {
      this.$bvModal.show("imageModal");
    },
    generateImage: function () {
      let url = this.myCroppa.generateDataUrl();
      if (!url) {
        return;
      }
      this.imgUrl = url;
      this.m_uploadProfile()
    },
    m_uploadProfile() {
      let imgUrl = this.imgUrl;
      if (imgUrl == null) return;

      var l_blob = imgUrl.slice(0, imgUrl.size, "image/*");
      var type = l_blob.substring(
        "data:image/".length,
        l_blob.indexOf(";base64")
      );
      var l_file = new File([l_blob], `${this.p_email}`, {
        type: type,
      });
      const l_profileRef = fb_profilestorageref.child(`${this.p_email}`);
      var l_uploadTask = l_profileRef
        .put(l_file)
        .then((p_snapshot) => {
          l_profileRef.getDownloadURL().then((p_downloadURL) => {
            this.d_imgurl = imgUrl;
            // Upload completed successfully, now we can get the download URL
            const l_tempCollection =
              this.p_role == "tutor"
                ? fb_tutorsCollection
                : fb_studentsCollection;

            l_tempCollection
              .doc(this.p_email)
              .set({ account: { avatar: this.d_imgurl } }, { merge: true })
              .then(() => {
                this.m_profileSavedPopup("Success", "Profile updated");
              });
          });
        })
        .catch((p_error) => {
          switch (p_error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        });
    },
    m_profileSavedPopup(title, msg) {
      this.$confirm({
        title: title,
        message: msg,
        button: {
          yes: "Ok",
        },
      });
    },
  },
  watch: {
    d_imgurl: function (value) {
      this.d_img = value;
    },
  },
};
</script>

<style>
.card-body-mobile {
  padding: 0.25rem;
}
.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}
.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}
.avatar-mobile {
  margin: 10px auto;
}
.card-img-overlay {
  display: none;
  transition: all 0.5s;
}
.card-img-overlay-mobile {
  bottom: inherit;
  padding: 0;
}
.card-img-overlay button {
  margin-top: 95px;
}
.card-img-overlay-mobile button {
  margin-top: 90px;
}
.card:hover .card-img-overlay {
  display: block;
}
.hideInput {
  display: none;
}
.card-footer-mobile {
  padding: 0.25rem 1.25rem;
}
.croppa-container canvas {
  width: 100% !important;
  height: auto !important;
  border: 2px solid grey;
}
</style>
