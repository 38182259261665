import {
    fb_sessionSettings,
} from "@/lib-core/lib-firebase";
export const g_preferredCurriculums = ['ib', 'ua', 'uk', 'fr', 'am'];
export const g_defaultPhone = "971504762522";
export const packagePrice = 1500;
export let sessionPrice = 0;

fb_sessionSettings.onSnapshot((d) => {
    sessionPrice = d.data().price;
});
