import { fb_auth } from '../lib-firebase';
const l_state = {
    st_authUser: null,
    st_error: null,
    st_isFacebookLogin: false,
};

const l_getters = {
    gt_authUser(l_state) {
        return l_state.st_authUser;
    },
    gt_isAuthenticated(l_state) {
        return l_state.st_authUser != null;
    },
    gt_isVerified(l_state) {
        return l_state.st_authUser != null && ( l_state.st_authUser.emailVerified || l_state.st_isFacebookLogin );
    },
    gt_isFacebookLogin(l_state) {
        return l_state.st_authUser != null && l_state.st_authUser.providerData != null ? l_state.st_authUser.providerData[0].providerId.includes('facebook') : false;
    },
};

const l_actions = {
    ac_login(l_context, p_payload) {
        l_context.commit("mt_setUser", p_payload);
    },
    ac_verifyAuth(l_context) {
        if (l_context.getters.gt_authUser) {
            l_context.commit("mt_setError", null);
        } else {
            l_context.commit("mt_setUser", null);
            l_context.commit("mt_setError", true);
        }
    },
    async ac_logout(l_context) {
        await fb_auth.signOut()
        l_context.commit("mt_logout");
        return true;
    },
};

const l_mutations = {
    mt_setError(l_state, p_error) {
        l_state.st_errors = p_error;
    },
    mt_setUser(l_state, p_authUser) {
        if (p_authUser == null) {
            l_state.st_authUser = null;
            return
        }
        const { uid, email, emailVerified, displayName } = p_authUser
        const isEmailVerified = emailVerified || l_state.st_isFacebookLogin;
        l_state.st_authUser = { uid, email, emailVerified: isEmailVerified, displayName }
    },
    mt_logout(l_state) {
        l_state.st_authUser = null;
        l_state.st_errors = null;
    },
    mt_setIsFacebookLogin(l_state, isFacebookLogin) {
        return l_state.st_isFacebookLogin = isFacebookLogin;
    },
};

export default {
    namespaced: true,
    state: l_state,
    actions: l_actions,
    mutations: l_mutations,
    getters: l_getters
};
