import {
    Plugins, PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    Capacitor
} from '@capacitor/core';
import { fb_usersCollection } from "@/lib-core/lib-firebase";

const { PushNotifications, LocalNotifications } = Plugins;

const l_state = {
    st_notifications: true,
};

const l_getters = {
    gt_notifications: (l_state) => (l_payload) => {
        const userdoc = fb_usersCollection.doc(l_payload).get().then((ref) => {
            const userdata = ref.data();
            return userdata.settings != null && userdata.settings.notifications != null ? userdata.settings.notifications : true;
        });
        return userdoc;
    }
};

const l_actions = {
};

const l_mutations = {
    mt_notifications: (l_state, l_payload) => {
        fb_usersCollection.doc(l_payload.user).set({ 'settings': { 'notifications': l_payload.notifications } }, { merge: true });
    }
};

export default {
    namespaced: true,
    state: l_state,
    actions: l_actions,
    mutations: l_mutations,
    getters: l_getters
};
