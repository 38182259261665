<template>
  <!-- v-if="gt_isAuthenticated && gt_isVerified" -->
  <div id="main-wrapper">
    <transition name="fade-in">
      <div id="preloader" v-if="st_loading || !st_userBinded">
        <div class="preloader"><span></span><span></span></div>
      </div>
    </transition>
    <cmp-topnavbar v-if="st_userBinded"/>
    <div class="clearfix"></div>
    <section class="pb-0" v-if="st_userBinded">
      <div class="container">
        <div class="row">
          <section class="page-title" style="width: 100vw">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="breadcrumbs-wrap">
                    <h1 class="breadcrumb-title">
                      {{ gt_dashboardSection }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <router-view />
        </div>
      </div>
    </section>
    <cmp-verifybanner />
    <vue-confirm-dialog></vue-confirm-dialog>
  </div>
</template>

<script>
import c_topNavbar from "@/cmp-view/cmp-components/cmp-topnavbar.vue";
import c_verifybanner from "../cmp-components/cmp-verifybanner.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "cmp-dashboardlayout",
  components: {
    "cmp-topnavbar": c_topNavbar,
    "cmp-verifybanner": c_verifybanner,
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user", "gt_dashboardSection"]),
    ...mapGetters("md_auth", ["gt_isAuthenticated", "gt_isVerified"]),
    ...mapState({
      st_loading: (st_state) => st_state.md_fire.st_loading,
      st_userBinded: (st_state) => st_state.md_fire.st_userBinded,
      st_dashboardSection: (st_state) => st_state.md_fire.st_dashboardSection,
    }),
  },

  mounted() {
    this.m_topFunction();

    if (!this.gt_isAuthenticated) {
      this.$router.push({ name: "home" });
      return;
    }
  },
  methods: {
    m_topFunction() {
      window.document.body.scrollTop = 0; // For Safari
      window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style>
</style>