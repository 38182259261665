<template>
  <div
    class="main-verify-banner"
    v-if="st_userBinded && gt_isAuthenticated && !gt_isVerified"
  >
    <p style="color: #ffffff">
      Please
      <b-link to="verify" class="linkToVerify">click here</b-link>
      to verify your email.
    </p>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "cmp-verifybanner",
  computed: {
    ...mapGetters("md_auth", ["gt_isAuthenticated", "gt_isVerified"]),
    ...mapState({
      st_userBinded: (st_state) => st_state.md_fire.st_userBinded,
    }),
  },
};
</script>

<style scoped>
.main-verify-banner {
  background: rgb(131, 191, 111);
  position: fixed;
  bottom: 0;
  width: 100vw;
  text-align: center;
  z-index: 9999;
}
.linkToVerify {
  color: #647b9c;
}
.linkToVerify:hover {
  color: #fff;
}
</style>