<template>
  <div class="row" style="border-top: 1px solid #e9edf3; padding-top: 4px">
    <span class="mb-0" style="margin-left: 20px"
      >© {{ d_currentDate.getFullYear() }} 971Tutors</span
    >
    <b-link style="margin-left: 20px; color: #647b9c" to="/privacypolicy"
      >Policies</b-link
    >

    <ul class="footer-bottom-social" style="position: absolute; right: 0">
      <li>
        <a
          target="_blank"
          href="https://www.facebook.com/971tutors-107660957771038/"
          ><i class="ti-facebook"></i
        ></a>
      </li>
      <!-- <li>
            <a href="#"><i class="ti-twitter"></i></a>
          </li> -->
      <li>
        <a
          target="_blank"
          href="https://instagram.com/971tutors?igshid=7158e375danb"
          ><i class="ti-instagram"></i
        ></a>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/suzanne-said-b52224166/"
          ><i class="ti-linkedin"></i
        ></a>
      </li>
      <li>
        <img width="15" height="15" src="/img/visa.png" />
      </li>
      <li>
        <img width="15" height="15" src="/img/mastercard.png" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "cmp-footer",
  data() {
    return {
      d_currentDate: new Date(),
    };
  },
  // <footer class="light-footer">
  //   <div class="footer-bottom">
  //     <div class="container">
  //       <div class="row align-items-center">
  //         <div class="col-lg-6 col-md-6">
  //           <span class="mb-0 mr-5">© 2020 971Tutors</span>
  //           <span class="mb-0 mr-5"><b-icon-cursor></b-icon-cursor> Dubai</span>
  //           <!-- <span class="mb-0 mr-5"
  //             ><b-icon-telephone></b-icon-telephone> +971 50 382 4321</span
  //           > -->
  //         </div>

  //         <!-- <div class="col-lg-6 col-md-6 text-right">
  //           <ul class="footer-bottom-social">
  //             <li>
  //               <a href="#"><b-icon-facebook></b-icon-facebook></a>
  //             </li>
  //             <li>
  //               <a href="#"><b-icon-twitter></b-icon-twitter></a>
  //             </li>
  //             <li>
  //               <a href="#"><b-icon-instagram></b-icon-instagram></a>
  //             </li>
  //             <li>
  //               <a href="#"><b-icon-linkedin></b-icon-linkedin></a>
  //             </li>
  //           </ul>
  //         </div> -->
  //       </div>
  //     </div>
  //   </div>
  // </footer>
};
</script>

<style>
</style>

