import l_Vue from "vue";
import l_Vuex from "vuex";

import l_auth from "./auth.module";
import l_fire from "./fire.module";
import l_cart from "./cart.module";
import l_custom from "./custom.module";

import { vuexfireMutations } from 'vuexfire';

l_Vue.use(l_Vuex);

export default new l_Vuex.Store({
    strict: true,
    mutations: vuexfireMutations,
    actions: {
    },
    modules: {
        md_fire: l_fire,
        md_auth: l_auth,
        md_cart: l_cart,
        md_custom: l_custom,
    }
});
