<template>
  <div class="dashboard-navbar" style="margin: 0; padding: 0">
    <b-col
      v-if="gt_user && gt_user.account"
      cols="12"
      order="1"
      style="margin: 0; padding: 0"
    >
      <div class="d-user-avater" style="margin: 0; padding: 0">
        <cmp-useravatar
          :p_displayName="gt_user.account.displayName"
          :p_email="gt_user.account.email"
          :p_role="gt_user.account.role"
          :p_avatar="gt_user.account.avatar"
          :p_ratingCount="gt_user.account.ratingCount"
          :p_ratingValue="gt_user.account.ratingValue"
          class="mt-0 pt-0"
        />
      </div>
    </b-col>
    <b-col cols="12" order="2" style="margin: 0; padding: 0">
      <div :class="['d-navigation', g_isMobile ? 'd-navigation-mobile' : '']">
        <button
          v-if="!(gt_user && gt_user.account)"
          @click="m_goToSection('as')"
          class="btn btn-light sign-in-button"
          style="
            width: 100%;
            min-width: 203px;
            margin: 0;
            color: #647b9c;
            height: 70px;
          "
        >
          Sign in
        </button>
        <ul id="side-menu" :class="[g_isMobile ? 'side-menu-mobile' : '']">
          <li
            v-if="g_isMobile && ( !gt_user || ( gt_user && gt_user.account && gt_user.account.role != 'tutor' ) )"
            :class="st_dashboardSection == 'cart' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('checkout')"
              ><i class="ti-shopping-cart"></i>Cart</a
            >
          </li>
          <li v-if="g_isMobile && ( !gt_user || ( gt_user && gt_user.account && gt_user.account.role != 'tutor' ) )"><hr /></li>

          <li
            v-if="gt_user && gt_user.account"
            :class="st_dashboardSection == 'sessions' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('sessions')"
              ><i class="ti-layers"></i>Sessions</a
            >
          </li>
          <li
            v-if="gt_user && gt_user.account"
            :class="st_dashboardSection == 'account' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('account')"
              ><i class="ti-user"></i>Account</a
            >
          </li>
          <li
            v-if="gt_user && gt_user.account && gt_user.account.role == 'tutor'"
            :class="st_dashboardSection == 'tutoringsubjects' ? 'active' : ''"
          >
            <a
              href="javascript:void(0)"
              @click="m_goToSection('tutoringsubjects')"
              ><i class="ti-calendar"></i>Tutoring Subjects</a
            >
          </li>

          <li v-if="g_isMobile && gt_user && gt_user.account"><hr /></li>

          <li
            v-if="g_isMobile"
            :class="st_dashboardSection == 'education' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('education')"
              ><i class="ti-world"></i>Education</a
            >
          </li>

          <li
            v-if="g_isMobile"
            :class="st_dashboardSection == 'subjects' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('subjects')"
              ><i class="ti-book"></i>Subjects</a
            >
          </li>

          <li
            v-if="g_isMobile && ( !gt_user || ( gt_user && gt_user.account && gt_user.account.role != 'tutor' ) )"
            :class="st_dashboardSection == 'packages' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('packages')"
              ><i class="ti-package"></i>Packages</a
            >
          </li>

          <li v-if="g_isMobile"><hr /></li>

          <li
            v-if="g_isMobile"
            :class="st_dashboardSection == 'about' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('about')"
              ><i class="ti-layout-cta-left"></i>About</a
            >
          </li>

          <li
            v-if="g_isMobile"
            :class="st_dashboardSection == 'contact' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('contact')"
              ><i class="ti-email"></i>Contact</a
            >
          </li>

          <li v-if="g_isMobile"><hr /></li>

          <li
            v-if="g_isMobile"
            :class="st_dashboardSection == 'settings' ? 'active' : ''"
          >
            <a href="javascript:void(0)" @click="m_goToSection('settings')"
              ><i class="ti-settings"></i>Settings</a
            >
          </li>

          <li v-if="g_isMobile && gt_user && gt_user.account"><hr /></li>

          <li>
            <a
              v-if="gt_user && gt_user.account"
              href="javascript:void(0)"
              v-b-toggle.sidebar-right
              @click="m_signOut"
              ><i class="ti-power-off"></i>Sign Out</a
            >
          </li>
        </ul>
      </div>
    </b-col>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import c_userAvatar from "./cmp-useravatar.vue";
import { cfaSignOut } from "capacitor-firebase-auth";
import { g_isMobile } from "../../lib-core/lib-helpers/lib-utils";

export default {
  name: "cmp-dashboardsidebar",
  components: {
    "cmp-useravatar": c_userAvatar,
  },
  computed: {
    ...mapGetters("md_fire", ["gt_user"]),
    ...mapState({
      st_dashboardSection: (st_state) => st_state.md_fire.st_dashboardSection,
    }),
    g_isMobile() {
      return g_isMobile;
    },
  },
  props: {
    p_email: {
      type: String,
    },
  },

  methods: {
    async m_signOut() {
      cfaSignOut().subscribe();
      await this.$store.dispatch("md_auth/ac_logout");
      await this.$localforage.setItem("fs_tempRole", null);
      if (this.$route.name == "home") return;
      this.$router.push({ name: "home" });
    },
    m_goToSection(p_name) {
      if (this.$route.name == p_name) return;
      if (
        p_name != "account" &&
        this.gt_user &&
        this.gt_user.account &&
        this.gt_user.account.role == "tutor" &&
        (!this.gt_user.account.address ||
          this.gt_user.account.address.trim().length == 0)
      ) {
        let l_payload = {
          message: "Finish Account Setup to proceed",
          button: {
            yes: "Ok",
          },
        };
        this.$confirm(l_payload);
        return;
      }
      this.$store.commit("md_fire/mt_setDashboardSection", p_name);
      this.$router.push({ name: p_name });
      this.m_topFunction();
    },
    m_topFunction() {
      window.document.body.scrollTop = 0; // For Safari
      window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style>
.vue-avatar-cropper-demo {
  max-width: 100%;
}
.dashboard-navbar .b-avatar-text {
  color: #ffffff !important;
}

.d-navigation-mobile > .side-menu-mobile {
  padding: 8px 0;
}
.d-navigation-mobile > .side-menu-mobile > li > a {
  padding: 10px 15px;
}
.d-navigation-mobile > .side-menu-mobile > li > hr {
  margin: 7px 0;
}
</style>