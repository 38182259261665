import { firebase } from '@firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import "firebase/functions"
import "firebase/database"

const l_instance = 'l_production';
// firebase init - add your own config here
const l_firebaseConfig = {
    'l_development': {
        apiKey: "AIzaSyDeC8WkblqgUlmcFfsY939hwL5VaIOAWm4",
        authDomain: "turors--dev.firebaseapp.com",
        databaseURL: "https://turors--dev.firebaseio.com",
        projectId: "turors--dev",
        storageBucket: "turors--dev.appspot.com",
        messagingSenderId: "818001599200",
        appId: "1:818001599200:web:336759b1c670feab2d8e85",
        measurementId: "G-CG6R0X4TJ6"
    },
    'l_production': {
        apiKey: "AIzaSyD7DKHR4UDnDdUiywYXoohFXY4aTLm7rrA",
        authDomain: "tutors--prod.firebaseapp.com",
        databaseURL: "https://tutors--prod.firebaseio.com",
        projectId: "tutors--prod",
        storageBucket: "tutors--prod.appspot.com",
        messagingSenderId: "717396452332",
        appId: "1:717396452332:web:6498479c21c918de3916c9",
        measurementId: "G-ME7476H1XV"
    },
}
firebase.initializeApp(l_firebaseConfig[l_instance]);

// utils
const fb_db = firebase.firestore()
const database = firebase.database();
const fb_newBatch = () => { return fb_db.batch(); }
const fb_auth = firebase.auth()
const fb_functions = firebase.functions();
const fb_storage = firebase.storage().ref();
const fb_cvStorageref = fb_storage.child('cv');
const fb_profilestorageref = fb_storage.child('profiles');
const fb_storageTaskEvent = firebase.storage.TaskEvent;
const fb_Timestamp = firebase.firestore.Timestamp;
const fb_FieldValue = firebase.firestore.FieldValue;
const checkout = fb_functions.httpsCallable("checkout");
const getcheckout = fb_functions.httpsCallable('getcheckout');
const sendMail = fb_functions.httpsCallable('sendMail');
const sessionStatus = fb_functions.httpsCallable('sessionStatus');
const setAdmin = fb_functions.httpsCallable('setAdmin');
const toggleUserDisable = fb_functions.httpsCallable("toggleUserDisable");
const allocateSession = fb_functions.httpsCallable("allocateSession");
const bankTransfer = fb_functions.httpsCallable("bankTransfer");
const pushNotification = fb_functions.httpsCallable("pushNotification");
const performValidation = fb_functions.httpsCallable("performValidation");
const applyCode = fb_functions.httpsCallable("applyCode");

//use emulator
// fb_functions.useFunctionsEmulator("http://localhost:5001");
// fb_db.useEmulator("localhost", 8079);
// collection references
// const curriculumsCollection = db.collection('curriculums');
const fb_tutorsCollection = fb_db.collection('tutors');
const fb_studentsCollection = fb_db.collection('students');
const fb_dataCollection = fb_db.collection('data');
const fb_usersCollection = fb_db.collection('users');
const fb_tutorSubjectsCollection = fb_db.collection('tutorsubjects');
const fb_studentSessionsCollection = fb_db.collection('studentsessions');
const fb_tutorSessionsCollection = fb_db.collection('tutorsessions');
const fb_packagesCollection = fb_db.collection('packages'); // Get packages from db

const fb_curriculumsRef = fb_db.collection('data').doc('curriculums');
const fb_categoriesRef = fb_db.collection('data').doc('categories');
const fb_subjectsRef = fb_db.collection('data').doc('subjects');
const fb_eventsRef = fb_db.collection("events").doc("events")

const fb_settingsRef = fb_db.collection('settings').doc('971tutors');
const fb_sessionSettings = fb_db.collection('sessionSettings').doc('settings');

//auth providers
const fb_googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const fb_facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const fb_appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
fb_appleAuthProvider.addScope('email');
fb_appleAuthProvider.addScope('name');

// export utils/refs
export {
    fb_db,
    database,
    fb_newBatch,
    fb_auth,
    checkout,
    getcheckout,
    sendMail,
    sessionStatus,
    applyCode,
    allocateSession,
    pushNotification,
    setAdmin,
    toggleUserDisable,
    bankTransfer,
    performValidation,
    fb_cvStorageref,
    fb_profilestorageref,
    fb_storageTaskEvent,
    fb_googleAuthProvider,
    fb_facebookAuthProvider,
    fb_appleAuthProvider,
    // curriculumsCollection,
    fb_tutorsCollection,
    fb_studentsCollection,
    fb_dataCollection,
    fb_Timestamp,
    fb_FieldValue,
    fb_usersCollection,
    fb_tutorSubjectsCollection,
    fb_studentSessionsCollection,
    fb_tutorSessionsCollection,
    fb_packagesCollection,
    fb_curriculumsRef,
    fb_categoriesRef,
    fb_subjectsRef,
    fb_eventsRef,
    fb_settingsRef,
    fb_sessionSettings,
}