<template>
  <div>
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
    />
    <a :href="m_isMobile() ? m_whatsappUrl() : '#'" @click="!m_isMobile() ? m_number() : ''" class="support-float" :target="m_isMobile() ? '_blank' : ''">
      <i :class="['fa', m_isMobile() ? 'fa-whatsapp' : 'fa-phone' ,'my-float']"></i>
    </a>
  </div>
</template>

<script>
import { m_whatsappUrl, m_isMobile } from "../../lib-core/lib-helpers/lib-utils";
import { g_defaultPhone } from "../../lib-core/lib-helpers/lib-constants";

export default {
  name: "cmp-supportbutton",

  methods: {
    m_whatsappUrl() {
      return m_whatsappUrl();
    },
    m_isMobile() {
      return m_isMobile();
    },
    m_number() {
      alert('Support\'s number is +' + g_defaultPhone)
    }
  },
};
</script>

<style scoped>
.support-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 6vh;
  right: 20px;
  background-color: #83da6f;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
</style>