<template>
  <div>
    <b-avatar
      v-if="p_avatar && !d_hasImageError"
      style="margin-top: 5px"
      :src="p_avatar"
      :size="p_size"
      @img-error="d_onImageError"
    ></b-avatar>
    <b-avatar
      v-else
      style="margin-top: 5px"
      :text="co_shortName"
      :size="p_size"
      variant="success"
    ></b-avatar>
  </div>
</template>

<script>
export default {
  name: "cmp-tutoravatar",
  props: {
    p_name: {
      type: String,
      default: "",
    },
    p_email: {
      type: String,
      default: "",
    },
    p_avatar: {
      type: String,
      default: "",
    },
    p_size: {
      type: String,
      default: "6rem",
    },
  },
  computed: {
    co_shortName: function () {
      if (this.p_name != null && this.p_name.trim().length > 0) {
        let l_split = this.p_name.split(" ");
        if (l_split.length == 1) {
          return l_split[0].substring(0, Math.min(2, l_split[0].length));
        } else {
          return l_split[0].substring(0, 1) + l_split[1].substring(0, 1);
        }
      }
      return this.p_email.substring(0, Math.min(2, this.p_email.length));
    },
  },
  data() {
    return {
      d_hasImageError: null,
    };
  },
  watch: {
    p_avatar: function (p_value) {
      if (p_value != null)
        this.d_hasImageError = false;
    },
  },
  methods: {
    d_onImageError(e) {
      this.d_hasImageError = true;
    },
  },
};
</script>

<style>
</style>