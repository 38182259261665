import Vue from "vue";
import Router from "vue-router";
import { fb_auth } from "./lib-core/lib-firebase";

Vue.use(Router);


export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/.well-known/assetlinks.json',
        },
        {
            path: '/.well-known/apple-developer-merchantid-domain-association.txt',
        },
        {
            path: '/.well-known/apple-app-site-association'
        },
        {
            path: '/',
            name: 'home',
            meta: { layout: 'cmp-applayout' },
            //component: require("@/cmp-view/cmp-pages/Home.vue").default
            component: () => import("@/cmp-view/cmp-pages/cmp-home.vue"),
        },
        {
            path: '/settings',
            name: 'settings',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-settingsmobile.vue"),
        },
        {
            path: '/about',
            name: 'about',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-about.vue"),
        },
        {
            path: '/education',
            name: 'education',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-curriculums.vue"),
        },
        {
            path: '/subjects',
            name: 'subjects',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-subjects.vue"),
        },
        {
            props: true,
            path: '/banktransfer',
            name: 'banktransfer',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-banktransfer.vue"),
        },
        {
            path: '/privacypolicy',
            name: 'privacypolicy',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-privacypolicy.vue"),
        },
        {
            path: '/refundpolicy',
            name: 'refundpolicy',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-refundpolicy.vue"),
        },
        {
            path: '/subject/:p_id',
            name: 'subject',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-subject.vue"), props: true,
        },
        {
            path: '/tutorApplySubject',
            name: 'tutorApplySubject',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-apply.vue"),
        },

        {
            path: '/sessions',
            name: 'sessions',
            meta: { layout: 'cmp-dashboardlayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-sessions.vue"),
        },
        {
            path: '/account',
            name: 'account',
            meta: { layout: 'cmp-dashboardlayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-account.vue"),
        },
        {
            path: '/tutoringsubjects',
            name: 'tutoringsubjects',
            meta: { layout: 'cmp-dashboardlayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-tutoring.vue"),
        },


        {
            path: '/checkout',
            name: 'checkout',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-checkout.vue"),
        },
        {
            path: '/checkoutsuccess',
            name: 'checkoutsuccess',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-checkoutsuccess.vue"),
        },
        {
            path: '/contact',
            name: 'contact',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-contact.vue"),
        },
        {
            path: '/packages',
            name: 'packages',
            meta: { layout: 'cmp-applayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-packages.vue"),
        },
        {
            path: '/payment/redirect',
            // meta: { layout: 'cmp-applayout' },
            // redirect: '/redirect',
            component: () => import("@/cmp-view/cmp-pages/cmp-paymentredirect.vue")
        },
        {
            path: '/verify',
            name: 'verify',
            meta: { layout: 'cmp-accesslayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-verify.vue"),
        },
        {
            path: '/access',
            name: 'access',
            meta: { layout: 'cmp-accesslayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-login.vue"),
        },
        {
            path: "/forgotpassword",
            name: "forgotPassword",
            meta: { layout: 'cmp-accesslayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-forgotpassword.vue"),
            beforeEnter(to, from, next) {
                next();
                // const l_user = fb_auth.currentUser;
                // if (l_user) {
                //     next({ name: "home" });
                // } else {
                //     next();
                // }
            },
        },
        {
            path: '/as',
            name: 'as',
            meta: { layout: 'cmp-accesslayout' },
            component: () => import("@/cmp-view/cmp-pages/cmp-loginpicker.vue"),
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            meta: { layout: 'cmp-applayout' },
            component: require("@/cmp-view/cmp-pages/cmp-error.vue").default
        }
    ]
});



