<template>
  <div id="app" ref="main">
    <component :is="co_layout">
      <router-view />
    </component>
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import { Capacitor } from "@capacitor/core";
import { g_isMobile } from './lib-core/lib-helpers/lib-utils';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const default_layout = "cmp-applayout";
export default {
  name: "App",
  computed: {
    co_layout() {
      if (!this.$route) return default_layout;
      return this.$route.meta.layout || default_layout;
    },
    isMobile() {
      return Capacitor.platform != "web";
    },
  },
  async mounted() {
    await this.m_load();
  },
  methods: {
    async m_load() {
      await this.m_loadCart();
      await this.m_loadEducation();
      await this.m_loadCurriculum();
      await this.m_loadCategory();
      if(g_isMobile)
        await this.onloadDarkMode();
    },
    async onloadDarkMode() {
      const darkmodeStorage = await Storage.get({
        key: "darkmode",
      });
      const darkmode = darkmodeStorage != null ? JSON.parse(darkmodeStorage.value) : false;
      this.darkmode = darkmode;
      if(darkmode)
        this._addDarkTheme();
    },
    async m_loadCart() {
      const l_cart = await this.$localforage.getItem("fs_cart");
      this.$store.commit("md_cart/mt_setCart", l_cart || []);
    },
    async m_loadEducation() {
      const l_education = await this.$localforage.getItem("fs_education");
      this.$store.commit("md_fire/mt_setEducation", l_education || "a");
    },
    async m_loadCurriculum() {
      const l_curriculum = await this.$localforage.getItem("fs_curriculum");
      this.$store.commit("md_fire/mt_setCurriculum", l_curriculum || null);
    },
    async m_loadCategory() {
      const l_category = await this.$localforage.getItem("fs_category");
      this.$store.commit("md_fire/mt_setCategory", l_category || "all");
    },
    _addDarkTheme() {
      let darkThemeLinkEl = document.createElement("link");
      darkThemeLinkEl.setAttribute("rel", "stylesheet");
      darkThemeLinkEl.setAttribute("href", "/css/darktheme.css");
      darkThemeLinkEl.setAttribute("id", "dark-theme-style");

      let docHead = document.querySelector("head");
      docHead.append(darkThemeLinkEl);
    },
    _removeDarkTheme() {
      let darkThemeLinkEl = document.querySelector("#dark-theme-style");
      let parentNode = darkThemeLinkEl.parentNode;
      parentNode.removeChild(darkThemeLinkEl);
    },
    darkThemeSwitch() {
      let darkThemeLinkEl = document.querySelector("#dark-theme-style");
      if (!darkThemeLinkEl) {
        this._addDarkTheme();
      } else {
        this._removeDarkTheme();
      }
    },
  },
};
</script>
<style scoped>
.centerAll {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>