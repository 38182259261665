const l_state = {
    st_cartProducts: [],
};

const l_mutations = {
    mt_setCart: (l_state, p_cart) => {
        l_state.st_cartProducts = p_cart;
    },
    mt_addProduct: (l_state, p_product) => {
        let l_isFound = false;
        for (let l_index = 0; l_index < l_state.st_cartProducts.length; l_index++) {
            if (l_state.st_cartProducts[l_index].en == p_product.en && l_state.st_cartProducts[l_index].tutor.email == p_product.tutor.email) {
                l_isFound = true;
                l_state.st_cartProducts[l_index].quantity = parseInt(l_state.st_cartProducts[l_index].quantity) + parseInt(p_product.quantity);
            }
        }
        if (!l_isFound) {
            l_state.st_cartProducts.push(p_product);
        }
    },
    mt_removeProduct: (l_state, p_payload) => {
        for (let l_index = l_state.st_cartProducts.length - 1; l_index >= 0; l_index--) {
            if (l_state.st_cartProducts[l_index].en == p_payload.subject && l_state.st_cartProducts[l_index].tutor.email == p_payload.tutor) {
                l_state.st_cartProducts.splice(l_index, 1);
            }
        }
    },
    mt_changeProductQuantity: (l_state, p_payload) => {
        for (let l_index = l_state.st_cartProducts.length - 1; l_index >= 0; l_index--) {
            if (l_state.st_cartProducts[l_index].en == p_payload.en && l_state.st_cartProducts[l_index].tutor.email == p_payload.tutor.email) {
                l_state.st_cartProducts[l_index].quantity = p_payload.quantity;
            }
        }
    },
    mt_addPackage: (l_state, p_payload) => {
        let l_isFound = false;
        for (let l_index = 0; l_index < l_state.st_cartProducts.length; l_index++) {
            if (l_state.st_cartProducts[l_index].id && l_state.st_cartProducts[l_index].id == p_payload.id) {
                l_isFound = true;
                l_state.st_cartProducts[l_index].quantity = parseInt(l_state.st_cartProducts[l_index].quantity) + parseInt(p_payload.quantity);
            }
        }
        if (!l_isFound) {
            l_state.st_cartProducts.push(p_payload);
        }
    },
    mt_removePackage: (l_state, p_payload) => {
        for (let l_index = l_state.st_cartProducts.length - 1; l_index >= 0; l_index--) {
            if (l_state.st_cartProducts[l_index].id && l_state.st_cartProducts[l_index].id == p_payload.id) {
                l_state.st_cartProducts.splice(l_index, 1);
            }
        }
    },
    mt_changePackageQuantity: (l_state, p_payload) => {
        for (let l_index = l_state.st_cartProducts.length - 1; l_index >= 0; l_index--) {
            if (l_state.st_cartProducts[l_index].id && l_state.st_cartProducts[l_index].id == p_payload.id) {
                l_state.st_cartProducts[l_index].quantity = p_payload.quantity;
            }
        }
    },
}


const l_actions = {
    ac_addProduct: (l_context, p_product) => {
        l_context.commit('mt_addProduct', p_product);
        return true;
    },
    ac_removeProduct: (l_context, p_index) => {
        l_context.commit('mt_removeProduct', p_index);
        return true;
    },
    ac_addPackage: (l_context, p_product) => {
        l_context.commit('mt_addPackage', p_product);
        return true;
    },
    ac_removePackage: (l_context, p_index) => {
        l_context.commit('mt_removePackage', p_index);
        return true;
    },
};

const l_getters = {
    gt_getProductsInCart: l_state => l_state.st_cartProducts,
    gt_getPackagesInCart: l_state => l_state.st_cartProducts.filter((p_element) => { return !p_element.tutor }),
    gt_getSessionsInCart: l_state => l_state.st_cartProducts.filter((p_element) => { return p_element.tutor }),
    gt_getCartSize: l_state => l_state.st_cartProducts.length,
}



export default {
    namespaced: true,
    state: l_state,
    actions: l_actions,
    mutations: l_mutations,
    getters: l_getters
};