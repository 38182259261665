<template>
  <div class="layout-container">
    <transition name="fade-in">
      <div id="preloader" v-if="st_loading">
        <div class="preloader"><span></span><span></span></div>
      </div>
    </transition>
    <cmp-topnavbar />
    <!-- <transition name="fade-in-right"> -->
    <router-view />
    <!-- </transition> -->
    <vue-confirm-dialog></vue-confirm-dialog>
    <cmp-supportbutton />
    <cmp-verifybanner />
    <cmp-footer />
  </div>
</template>

<script>
import c_topNavbar from "../cmp-components/cmp-topnavbar.vue";
import c_footer from "../cmp-components/cmp-footer.vue";
import c_supportButton from "../cmp-components/cmp-supportbutton.vue";
import c_verifybanner from "../cmp-components/cmp-verifybanner.vue";
import { mapState } from "vuex";
export default {
  name: "cmp-applayout",
  components: {
    "cmp-topnavbar": c_topNavbar,
    "cmp-footer": c_footer,
    "cmp-supportbutton": c_supportButton,
    "cmp-verifybanner": c_verifybanner,
  },
  computed: {
    ...mapState({
      st_loading: (st_state) => st_state.md_fire.st_loading,
      st_user: (st_state) => st_state.md_fire.st_user,
    }),
  },
  watch: {
    st_user: {
      immediate: true,
      handler(newVal) {
        if (
          newVal &&
          newVal.account &&
          newVal.account.role == "tutor" &&
          (!newVal.account.address || newVal.account.address.trim().length == 0)
        ) {
          this.$router.push({ name: "account" });
        }
      },
    },
  },
};
</script>

<style>
</style>