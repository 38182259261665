var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header header-light head-shadow",staticStyle:{"z-index":"101","height":"70px"}},[(false)?_c('div',{staticStyle:{"z-index":"999999999","background":"#eee","color":"#83bf6f","text-align":"center","font-size":"12px"},attrs:{"width":"100%","height":"10px"}},[_vm._v(" Please note that 971tutors.com is running in testing mode ")]):_vm._e(),_c('div',{staticClass:"container-fluid",staticStyle:{"background":"white","padding-top":"env(safe-area-inset-top)"}},[_c('b-navbar',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.g_isMobile ? _vm.emptyFunc : _vm.m_closeNav),expression:"g_isMobile ? emptyFunc : m_closeNav"}],staticClass:"navigation navigation-landscape",attrs:{"fixed":"top","toggleable":"lg","id":"navigation"}},[_c('transition',{attrs:{"name":"fade"}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.c_isMobile() && !_vm.isMainPage() && _vm.co_showBackButton),expression:"c_isMobile() && !isMainPage() && co_showBackButton"}],staticClass:"h3 p-0 m-0",on:{"click":function($event){return _vm.goBack()}}},[_c('b-icon',{attrs:{"icon":"arrow-left"}})],1)]),_c('transition',{attrs:{"name":"fade"}},[_c('img',{staticClass:"logo",staticStyle:{"cursor":"pointer","width":"100px","padding-bottom":"15px"},attrs:{"src":"/img/logo.png"},on:{"click":function($event){_vm.m_closeNav();
            _vm.m_route('home');}}})]),(!_vm.g_isMobile)?_c('b-navbar-toggle',{staticStyle:{"float":"right"},attrs:{"target":"nav-collapse"}}):_c('b-navbar-toggle',{staticStyle:{"float":"right"},attrs:{"target":"sidebar-right"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"active":_vm.m_isActiveRoute('education'),"active-class":"menu-highlighted"},on:{"click":function($event){return _vm.m_route('education')}}},[_vm._v(" Education ")]),_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"active":_vm.m_isActiveRoute('subjects'),"active-class":"menu-highlighted"},on:{"click":function($event){return _vm.m_route('subjects')}}},[_vm._v("Subjects ")]),(
              !_vm.gt_isAuthenticated ||
              !_vm.gt_user ||
              !_vm.gt_user.account ||
              _vm.gt_user.account.role != 'tutor'
            )?_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"active":_vm.m_isActiveRoute('packages'),"active-class":"menu-highlighted"},on:{"click":function($event){return _vm.m_route('packages')}}},[_vm._v("Packages ")]):_vm._e(),_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"active":_vm.m_isActiveRoute('about'),"active-class":"menu-highlighted"},on:{"click":function($event){return _vm.m_route('about')}}},[_vm._v("About ")]),_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"active":_vm.m_isActiveRoute('contact'),"active-class":"menu-highlighted"},on:{"click":function($event){return _vm.m_route('contact')}}},[_vm._v("Contact ")]),(!_vm.gt_isAuthenticated)?_c('b-nav-item',{staticClass:"main-nav-item",attrs:{"id":"become-tutor-btn","active-class":"menu-highlighted"},on:{"click":_vm.m_goToBecomeATutor}},[_vm._v("Become a Tutor ")]):_vm._e()],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[(
              !_vm.gt_isAuthenticated ||
              !_vm.gt_user ||
              !_vm.gt_user.account ||
              _vm.gt_user.account.role != 'tutor'
            )?_c('button',{staticClass:"btn btn-main-cart",on:{"click":function($event){_vm.m_closeNav();
              _vm.m_route('checkout');}}},[_c('b-icon-cart4',{staticStyle:{"color":"#647b9c","opacity":"0.6"},attrs:{"font-scale":"1"}}),_c('b-badge',{staticStyle:{"background":"#f5f5f5","color":"#647b9c"},attrs:{"id":"cart-badge-main","pill":""}},[_vm._v(_vm._s(_vm.gt_getCartSize))])],1):_vm._e(),(!_vm.gt_isAuthenticated)?_c('button',{staticClass:"btn btn-light",staticStyle:{"width":"100%","min-width":"203px","margin":"0","color":"#647b9c","height":"70px"},on:{"click":function($event){_vm.m_closeNav();
              _vm.m_route('as');}}},[_vm._v(" Sign in ")]):(_vm.gt_isAuthenticated && _vm.gt_user && _vm.gt_user.account)?_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-right",modifiers:{"sidebar-right":true}}],staticClass:"btn btn-light",staticStyle:{"width":"100%","min-width":"203px","margin":"0","color":"#647b9c","height":"70px"}},[_c('cmp-tutoravatar',{staticStyle:{"display":"inline"},attrs:{"p_size":"2rem","p_name":_vm.gt_user.account.displayName,"p_email":_vm.gt_user.account.email,"p_avatar":_vm.gt_user.account.avatar}}),_c('span',[_vm._v(" "+_vm._s(_vm.gt_user.account.displayName && _vm.gt_user.account.displayName != "" ? _vm.gt_user.account.displayName : _vm.gt_user.account.email)+" ")])],1):_c('button',{staticClass:"btn btn-light",staticStyle:{"width":"100%","min-width":"203px","margin":"0","color":"#647b9c","height":"70px"},on:{"click":function($event){_vm.m_closeNav();
              _vm.m_route('as');}}},[_vm._v(" Sign in ")]),(false)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.c_isMobile),expression:"c_isMobile"}],ref:"version",staticClass:"hide-version"},[_vm._v("version 18.0")]):_vm._e()])],1)],1)],1),_c('b-sidebar',{attrs:{"sidebar-class":"marginIOS","id":"sidebar-right","bg-variant":"white","right":"","shadow":"","no-header":"","backdrop-variant":"transparent","backdrop":""}},[_c('cmp-dashboardsidebar')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }