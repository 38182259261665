import l_Vue from "vue";
import l_App from './App.vue'
import router from "./router";
import store from "@/lib-core/lib-store"
import { fb_auth, fb_tutorsCollection, fb_studentsCollection, fb_usersCollection } from '@/lib-core/lib-firebase'
import l_moment from 'moment'
import l_vueConfirmdialog from 'vue-confirm-dialog'
import l_Localforage from 'v-localforage'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../public/css/styles.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import LoadScript from 'vue-plugin-load-script';
import Croppa from 'vue-croppa';
 
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';

const { App, Browser, PushNotifications, LocalNotifications, Network } = Plugins;

import ToggleButton from 'vue-js-toggle-button'

l_Vue.use(Croppa);

l_Vue.use(LoadScript);
// Install BootstrapVue
l_Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
l_Vue.use(IconsPlugin);

//custom plugins
l_Vue.use(ToggleButton)

l_Vue.prototype.$moment = l_moment
l_Vue.use(l_Localforage)

l_Vue.use(l_vueConfirmdialog)
l_Vue.component('vue-confirm-dialog', l_vueConfirmdialog.default)


l_Vue.config.productionTip = false


import l_AppLayout from '@/cmp-view/cmp-layouts/cmp-applayout.vue';
import l_AccessLayout from '@/cmp-view/cmp-layouts/cmp-accesslayout.vue';
import l_DashboardLayout from '@/cmp-view/cmp-layouts/cmp-dashboardlayout.vue';
import './service-worker'

l_Vue.component("cmp-applayout", l_AppLayout);
l_Vue.component("cmp-accesslayout", l_AccessLayout);
l_Vue.component("cmp-dashboardlayout", l_DashboardLayout);

if (Capacitor.platform !== 'web') {
  Network.addListener('networkStatusChange', (status) => {
    if (!status.connected) {
      store.commit("md_fire/mt_setPageNoInternet", true);
    }
    else {
      store.commit("md_fire/mt_setPageNoInternet", false);
    }
  });
}

App.addListener('appUrlOpen', function (data) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = data.url.split('.app').pop();
  // const is_payment_slug = slug.match('payment\/redirect');
  // We only push to the route if there is a slug present
  if (slug) {
    Browser.close();
    router.push({ path: slug });
  }
});

App.addListener('backButton', function (data) {
  if (router.history.current.path != "/" && router.history.current.path.length > 0) {
    store.commit("md_fire/mt_setDashboardSection", '');
    window.history.back();
  }
  else
    App.exitApp();
});

router.beforeEach(async (to, from, next) => {
  if (store.state.md_auth.st_authUser && !store.getters["md_auth/gt_isVerified"] && to.name == 'banktransfer') {
    next('/');
  }
  if (store.state.md_auth.st_authUser && store.getters["md_auth/gt_isVerified"] && to.name == 'verify') {
    next(false);
  }

  const lastRouteName = await l_Vue.$localforage.getItem('lastRoute');
  if (store.state.md_auth.st_authUser && lastRouteName == "account" && to.name == "access") {
    next('/');
  }
  if (store.state.md_auth.st_authUser) {
    let user = store.state.md_auth.st_authUser;
    if (user != null && user.email != null) {

      let l_collection =
        store.getters["md_fire/gt_userRole"] == "tutor"
          ? fb_tutorsCollection
          : fb_studentsCollection;
      let userAccount = await l_collection
        .doc(user.email)
        .get();

      if (isFilledAccountFields(userAccount)) {
        next();
      }
      else {

        if (to.name != 'account') {
          alert('Please fill your account info before proceding')
          next('account');
        }
      }
    }
  }


  next();
});

router.afterEach(async to => {
  await l_Vue.$localforage.setItem('lastRoute', to.name);
});

let l_app;
fb_auth.onAuthStateChanged(async (p_user) => {

  let p_ModifiedUser = JSON.parse(JSON.stringify(p_user));
  if (p_ModifiedUser != null && p_ModifiedUser.providerData != null) {
    p_ModifiedUser.emailVerified = p_ModifiedUser.emailVerified || p_ModifiedUser.providerData[0].providerId.includes('facebook');
  }

  await store.dispatch("md_auth/ac_login", p_ModifiedUser);

  if (!l_app) {
    //move some data fetch to another location ( this being called more then once)
    l_app = new l_Vue({
      router,
      store,
      render: h => h(l_App)
    }).$mount("#app");

    await store.dispatch("md_fire/ac_bindCategories");
    await store.dispatch("md_fire/ac_init");
    await store.dispatch("md_fire/ac_bindSubjects");

  }
  if (p_ModifiedUser != null) {

    if (Capacitor.platform !== 'web') {
      PushNotifications.requestPermission().then((permission) => {
        if (permission.granted) {
          PushNotifications.register();
          // Register with Apple / Google to receive push via APNS/FCM
        } else {
          // No permission for push granted
        }
      });


      PushNotifications.addListener('registration', function (token) {
        fb_usersCollection.doc(p_ModifiedUser.email).set({ 'token': token }, { merge: true });
        if (Capacitor.platform === 'android') {
          const notificationChannel = {
            id: 'default',
            name: 'Default',
            description: 'default',
            importance: 5,
            visibility: 1
          };
          const notificationChannel2 = {
            id: 'channel2',
            name: 'Pop notifications',
            description: 'Pop notifications',
            importance: 5,
            visibility: 1
          };

          PushNotifications.createChannel(notificationChannel);
          LocalNotifications.createChannel(notificationChannel);
          PushNotifications.createChannel(notificationChannel2);
          LocalNotifications.createChannel(notificationChannel2);
        }
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        async (notification) => {
          if (Capacitor.platform === 'android') {
            LocalNotifications.schedule({

              notifications: [{
                title: notification.title,
                body: notification.body,
                id: new Date().getUTCMilliseconds(),
                schedule: {
                  at: new Date(Date.now() + 1000)
                },
                extra: notification.data,
                channelId: 'channel2'
              }]
            });
          }
        }
      );
    }

    const l_tempRole = await l_app.$localforage.getItem("fs_tempRole");
    const l_fromCheckout = await l_app.$localforage.getItem("fs_fromCheckout");
    await store.dispatch("md_fire/ac_createOrBind", { email: p_ModifiedUser.email, displayName: p_ModifiedUser.displayName, emailVerified: p_ModifiedUser.emailVerified, tempRole: l_tempRole });

    if (p_ModifiedUser.emailVerified) {

      if (window.location.pathname == '/verify') {
        if (l_fromCheckout) {
          // await l_app.$localforage.setItem("fs_fromCheckout", false);
          l_app.$router.push({ name: 'checkout' });
        } else {
          l_app.$router.push({ name: 'account' });
        }
      }
      else if (l_fromCheckout) {
        // await l_app.$localforage.setItem("fs_fromCheckout", false);
        l_app.$router.push({ name: 'checkout' });
      }
      // else {
      //   l_app.$router.push({ name: 'home' });
      // }
    }

  } else {
    await store.dispatch("md_fire/ac_unbind");
    if (window.location.pathname == '/verify') {
      l_app.$router.push({ name: 'home' });
    }
  }
})

function isFilledAccountFields(userAccount) {
  let account = null;
  if (userAccount.data() != null)
    account = userAccount.data().account;
  let res = true;
  if (account != null)
    res = validateField(account.address) && validateField(account.bio) && validateField(account.displayName) && account.dob.seconds != null && typeof account.dob.seconds === "number" && validateField(account.gender) && validateField(account.lang) && validateField(account.nationality) && validateField(account.phone);
  return res;
}
//
function validateField(field) {
  return field != null && field.length > 0;
}