<template>
  <div class="text-center">
    <b-form-rating
      :readonly="p_readonly"
      precision="2"
      variant="warning"
      :value="co_starsLabel"
      no-border
      inline
    >
    </b-form-rating>
    <b-badge style="transform: translateY(-4px)"
      >{{ co_countLabel }} Votes</b-badge
    >
  </div>
</template>

<script>
export default {
  name: "cmp-tutorating",
  props: ["p_ratingValue", "p_ratingCount", "p_readonly"],

  computed: {
    co_starsLabel: function () {
      return this.p_ratingValue / this.p_ratingCount;
    },
    co_countLabel: function () {
      return this.p_ratingCount;
    },
  },
};
</script>

<style>
</style>